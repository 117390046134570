import axios from 'axios'

axios.defaults.baseURL = 'https://gateway.boredpanda.com';
axios.defaults.headers.post['Content-Type'] = 'application/json';

export function fetchPost(slug) {
    if (!slug) {
        return null;
    }

    return axios.get('./post?slug=' + slug).then(x => x.data).then((post) => {
        return post.data;
    });
}
