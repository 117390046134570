<template>
    <main class="main bp-container clearfix">
        <single-post v-if="post" :post="post"></single-post>
    </main>
</template>

<script>

import usePostGateway from '@/composables/usePostGateway';
import {useRoute} from 'vue-router';
// import ListPost from '@/components/ListPost';
// import SinglePost from '@/components/SinglePost';
import {defineAsyncComponent} from "vue";
// import axios from "axios";

export default {
    name: 'post',
    setup() {
        const route = useRoute();
        const slug = route.path.replaceAll('/', '');
        const {post} = usePostGateway(slug)

        return {
            post
        }
    },
    mounted() {
        this.$ga.page('/')
        // this.axios.get('./post?slug=' + this.slug).then(x => x.data).then((post) => {
        //     this.post = post.data;
        // });
    },
    components: {
        // ListPost: defineAsyncComponent(() => import('@/components/ListPost')),
        SinglePost: defineAsyncComponent(() => import('@/components/SinglePost')),
    },
    computed: {
        type() {
            if (!this.post) {
                return null;
            }

            return this.post && this.post.content_type === 'open_list' ? 'list-post' : 'single-post';
        }
    }
}
</script>
